$color-light: #424242;
$color-dark: #1A1B1E;
$color-danger: #f44336;
$color-info: #ffed4d;
$color-success: #50b366;

:root {
    --color-primary: var(--theme-primary-500);
    --color-primary-contrast: var(--theme-primary-contrast-500);
    --color-background: rgba(255, 255, 255, 0.8);
    --color-accent: var(--theme-accent-500);
    --color-accent-contrast: var(--theme-accent-contrast-500);

    --color-danger: #f44336;
    --color-success: #50b366;
    --color-info: #ffed4d;
}

$global-breakpoint: md;

.danger {
    color: $color-danger !important;
}

.background-danger {
    background-color: $color-danger !important;
    color: #FFF !important;

    button {
        color: #FFF;
    }
}


.background-success {
    background-color: $color-success !important;
    color: #FFF !important;

    button {
        color: #FFF;
    }
}

.success {
    color: $color-success !important;
}