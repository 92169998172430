/* For use in src/lib/core/theming/_palette.scss */
$snowowl-warn: (50 : #fbe4e5,
    100 : #f4bdbf,
    200 : #ed9195,
    300 : #e6646a,
    400 : #e0434a,
    500 : #db222a,
    600 : #d71e25,
    700 : #d2191f,
    800 : #cd1419,
    900 : #c40c0f,
    A100 : #fff0f0,
    A200 : #ffbdbd,
    A400 : #ff8a8b,
    A700 : #ff7072,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$snowowl-info: (50 : #fcfbeb,
    100 : #f7f6cd,
    200 : #f2f0ac,
    300 : #ede98b,
    400 : #e9e572,
    500 : #e5e059,
    600 : #e2dc51,
    700 : #ded848,
    800 : #dad33e,
    900 : #d3cb2e,
    A100 : #ffffff,
    A200 : #fffee2,
    A400 : #fffbaf,
    A700 : #fffa96,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));

$snowowl-success: (50 : #f3fff3,
    100 : #e1fee0,
    200 : #cefecb,
    300 : #bafdb6,
    400 : #abfca7,
    500 : #9cfc97,
    600 : #94fc8f,
    700 : #8afb84,
    800 : #80fb7a,
    900 : #6efa69,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #edffec,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    ));