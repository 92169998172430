/* Logic for breakpoints */
$breakpoints: ("extra-small": 320px,
    "small": 650px,
    "mobile": 950px,
    "medium": 1200px,
    "large": 1500px,
    "extra-large": 1800px) !default;

@mixin respond-to($breakpoint) {
    @if map-has-key($breakpoints, $breakpoint) {
        @media (max-width: map-get($breakpoints, $breakpoint)) {
            @content;
        }
    }

    @else {
        @warn "Breakpoint not found `#{$breakpoint}`";
    }
}

@mixin respond-until($media) {
    @each $breakpoint in $breakpoints {
        @if $media==nth($breakpoint, 1) {
            @media (max-width: #{nth($breakpoint, 2)}) {
                @content;
            }
        }
    }
}