// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
@use "./styles/mixins" as mixins;
@use "./styles/variables" as vars;
// Plus imports for other components in your app.

@import "./styles/palette";
@import "./styles/theme";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$snow-owl-admin-primary: mat.define-palette($dynamic-theme-primary);
$snow-owl-admin-accent: mat.define-palette($dynamic-theme-accent);

// The warn palette is optional (defaults to red).
$snow-owl-admin-warn: mat.define-palette($snowowl-warn);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$snow-owl-admin-theme: mat.define-light-theme(
    (
        color: (
            primary: $snow-owl-admin-primary,
            accent: $snow-owl-admin-accent,
            warn: $snow-owl-admin-warn
        )
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($snow-owl-admin-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

* {
    box-sizing: border-box;
}

// Angular Material overrides
.mat-form-field-appearance-outline .mat-form-field-wrapper {
    margin: 0.25em 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-subscript-wrapper {
    margin-top: 0.5em !important;
}

.mat-form-field {
    margin-top: 1em;
}

// Location Groups google org chart styles, has to be global
.google-visualization-orgchart-linenode {
    border-color: var(--theme-primary-500);
}

.google-org-chart-node {
    line-height: 0.8rem;
    border: 1px solid black;
    height: 4.5rem;
    width: 4.5rem;
    position: relative;
    cursor: pointer;

    background-color: var(--color-background) !important;
    background: var(--color-background) !important;
    border: 1px solid var(--theme-primary-500) !important;

    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 12px;
    font-weight: 500;

    border-radius: 4px;
    padding: 5px;

    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);

    button {
        background-color: transparent;
        border: none;
        cursor: pointer;

        mat-icon {
            user-select: none;
            background-repeat: no-repeat;
            display: inline-block;
            fill: currentColor;
            height: 24px;
            width: 24px;

            font-family: "Material Icons";
            font-weight: normal;
            font-style: normal;
            font-size: 24px;
            line-height: 1;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
        }
    }

    .network-name {
        font-size: 1rem;
        font-weight: 700;
    }

    .delete-node {
        position: absolute;
        top: -4px;
        right: 0;

        color: var(--color-danger);

        mat-icon {
            height: 1rem;
            width: 1rem;
            font-size: 1rem;
        }
    }

    .add-node {
        color: var(--theme-primary-500);
        position: relative;
        right: 1px;
        top: 1px;
    }
}

.container {
    padding: 1rem;
    margin: 1rem;
    background-color: #fff;

    @include mat.elevation(2);

    &--noelevation {
        box-shadow: none;
    }

    .mat-expansion-panel,
    .mat-card {
        @include mat.elevation(1);
    }
}

mat-cell,
mat-header-cell {
    padding-right: 1rem;
    overflow-wrap: anywhere !important;
}

.dialog {
    $dialog: &;

    &__header {
        color: vars.$color-light;
        font-size: 1.25rem !important;
        display: flex !important;
        justify-content: space-between;
    }

    &__message {
        margin-bottom: 2rem;

        p {
            overflow-wrap: break-word;
        }

        .emphasis {
            color: var(--color-primary);
            font-size: 22px;
            margin-top: 8px;
        }
    }

    &__inputs {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
    }

    &__input {
        width: 100%;

        &.mat-focused,
        &.mat-form-field-should-float {
            ::ng-deep {
                .mat-form-field-label-wrapper {
                    top: 0rem;
                }
            }
        }
    }

    &__buttons {
        justify-content: flex-end;
    }

    &__button {
        min-width: 100px;

        & ~ .dialog__button {
            margin-left: 1rem;
        }
    }

    &--full-screen {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        overflow: hidden;

        #{$dialog}__image {
            max-width: 100%;
            max-height: 100%;
            height: auto;
            object-fit: scale-down;
        }

        #{$dialog}__title {
            align-self: flex-start;
        }

        #{$dialog}__button {
            align-self: flex-end;
        }
    }
}

span.spacer {
    flex: 1;
}

.form {
    display: flex;

    & > app-form-control {
        & ~ app-form-control {
            margin-left: 1rem;
        }

        &[ng-reflect-type="checkbox"] {
            margin-top: 1.5em;
        }
    }

    app-form-control {
        display: block;
    }

    &__input {
        display: block;
        position: relative;
        padding-bottom: 0.5em;
        width: 100%;

        &.mat-focused {
            .form__label {
                color: var(--color-primary);
            }
        }

        &.mat-form-field-invalid {
            .form__label {
                color: vars.$color-danger;
            }
        }

        mat-chip-list {
            .mat-chip-list-wrapper {
                justify-content: flex-end;
            }

            mat-chip {
                min-height: 24px;
                padding: 4px 4px 3px 10px !important;
                color: vars.$color-light !important;
                background-color: var(--color-accent) !important;

                mat-icon {
                    color: vars.$color-light !important;
                    opacity: 1 !important;
                }
            }
        }

        input {
            width: 100% !important;
            transform: translateY(-4px);

            &::placeholder {
                color: var(--color-primary);
                text-transform: none !important;
            }
        }

        &--file {
            padding: 20px 0;
            margin-top: 20px;

            .form__label {
                padding-left: 10px;
            }
        }

        &--auto-width {
            width: auto;
        }
    }

    &__fileinput {
        display: flex;
        align-items: center;

        button {
            background-color: #f9f9f9;
        }

        & > span {
            font-family: "Rooney";
            text-transform: none;
        }

        & > *:not(:last-child) {
            margin-right: 1em;
        }
    }

    &__filepreview {
        height: 3rem;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__label {
        position: absolute;
        top: -28px;
        font-size: 0.9em;
        color: vars.$color-light;
    }

    &__list {
        width: 100%;

        & ~ & {
            margin-left: 2em;
        }
    }

    &__listheader {
        font-size: 0.9em;
        color: vars.$color-light;
        display: flex;
        justify-content: space-between;
        align-items: center;

        mat-button {
            width: 28px;
            height: 28px;

            mat-icon {
                font-size: 18px;
            }
        }
    }

    &__group {
        display: block;
        padding-left: 2em;
        padding-right: 2em;
        transition: flex 0.2s ease-in-out;
        flex: 1;

        &--expanded {
            flex: 2;
        }

        &--nopadding {
            padding: 0;
        }

        &--row {
            display: flex;
        }

        &:first-child {
            padding-left: unset;
        }

        &:last-child {
            padding-right: unset;
        }

        & ~ & {
            border-left: 2px solid #f1f1f1;
        }
    }

    &__placeholder {
        color: vars.$color-light;
        font-size: 0.9em;
        text-align: center;
        padding-top: 2em;
    }
}

.mat-slide-toggle-label {
    sup {
        font-size: 80%;
        align-self: start;
        margin-right: 0.3em;
    }

    &--tirewall {
        flex-wrap: wrap;
    }
}

.mat-list-item-disabled {
    opacity: 0.4;
}

.mat-form-field-appearance-outline.mat-force-focused .mat-form-field-outline-thick {
    color: var(--color-primary) !important;
    opacity: 1 !important;
}

a,
a:visited,
a:focus {
    text-decoration: none;
    color: inherit;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-preview,
.cdk-drag-dragging {
    &,
    &:active,
    .cdk-drag,
    a {
        cursor: grabbing !important;
    }
}

.cdk-drop-list-dragging {
    .cdk-drag {
        transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
    }
}

.page-heading {
    color: vars.$color-dark;
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-bottom: 1rem;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    button {
        & ~ button,
        & ~ app-feature-reboot {
            margin-left: 1rem;
        }
    }

    &__saving {
        font-size: 1rem;
        font-style: italic;
        color: vars.$color-light;
        display: flex;
        align-items: center;

        mat-spinner {
            margin-right: 0.5rem;
        }
    }
}

button {
    span ~ mat-icon {
        mat-spinner {
            right: -0.2rem;
        }
    }

    mat-icon ~ span {
        mat-spinner {
            left: -0.2rem;
        }
    }
}

mat-expansion-panel-header {
    padding: 0 1rem !important;
}

.mat-list,
.mat-nav-list {
    .mat-list-item .mat-line {
        max-width: 12rem;
        line-height: 1.1rem;
        word-wrap: break-word !important;
        white-space: normal !important;
    }
}
